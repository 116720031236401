/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
//Prod

// const awsmobile = {
//     "aws_project_region": "us-east-2",
//     "aws_cognito_identity_pool_id": "us-east-2:cb665aa6-c279-477c-90b0-a885c5293e2b",
//     "aws_cognito_region": "us-east-2",
//     "aws_user_pools_id": "us-east-2_Xv7Rqe9m0",
//     "aws_user_pools_web_client_id": "1pvpfhfuln2mvdgst4epfnqt6k",
//     "oauth": {
//         "domain": "notilite-nterprod.auth.us-east-2.amazoncognito.com",
//         "scope": [
//             "phone",
//             "email",
//             "openid",
//             "profile",
//             "aws.cognito.signin.user.admin"
//         ],
//         "redirectSignIn": "https://localhost:8080/login/",
//         "redirectSignOut": "https://localhost:8080/signout/",
//         "responseType": "code"
//     },
//     "federationTarget": "COGNITO_USER_POOLS"
// };

//QA

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:6544fe2e-4947-41af-9ec3-3618ab3d6fb3",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_THTkAcmUC",
    "aws_user_pools_web_client_id": "2tc8t4ceitbqbkrv0tbkrcem57",
    "oauth": {
        "domain": "notilite-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:8080/login/",
        "redirectSignOut": "https://localhost:8080/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

export default awsmobile;
